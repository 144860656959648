import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [0,13];

export const dictionary = {
		"/": [~15],
		"/about-dubclub": [16],
		"/auth/complete/account": [17,[2]],
		"/auth/complete/social": [~18,[2]],
		"/auth/login": [19,[2]],
		"/auth/verify": [20,[2]],
		"/capper/affiliates/links": [21,[3]],
		"/capper/affiliates/members": [22,[3]],
		"/capper/affiliates/sales": [23,[3]],
		"/capper/dashboard": [24],
		"/capper/discord/custom-bot/manage": [25,[4]],
		"/capper/discord/custom-bot/setup": [26,[4]],
		"/capper/discord/manage": [~27],
		"/capper/discord/setup": [~28],
		"/capper/manage-prices": [~29,[5],[6]],
		"/capper/products": [~30],
		"/capper/profile/[name_or_price]": [~31],
		"/capper/publish": [~32],
		"/capper/referral": [33,[7]],
		"/capper/stream/[name]": [~34,[8]],
		"/footer": [35],
		"/plays/[textpickKey]": [~36],
		"/plays/[textpickKey]/[viewerKey]": [~37],
		"/product/new": [38],
		"/publish/play/[capperKey]": [~39,[9]],
		"/publish/sales": [~40,[10]],
		"/r/capper": [41],
		"/r/connected-accounts": [~42],
		"/r/discover-cappers": [43,[11]],
		"/r/discover-cappers/(discovery)/cappers": [44,[11,12]],
		"/r/discover-cappers/(discovery)/leagues": [45,[11,12]],
		"/r/discover-cappers/(discovery)/plays": [46,[11,12]],
		"/r/discover-cappers/(discovery)/search": [47,[11,12]],
		"/r/discover-cappers/(discovery)/sports": [48,[11,12]],
		"/samples/[sampleKey]": [~49],
		"/samples/[sampleKey]/[viewerKey]": [~50],
		"/t/[productKey]": [51,[13]],
		"/t/[productKey]/edit": [52],
		"/t/[productKey]/faqs": [53,[13]],
		"/t/[productKey]/notifications": [54,[13]],
		"/viewm/[marketing=lookup_key]/[user=lookup_key]": [~55,[14]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';